




































































































































































































































































































































































































import Vue from "vue";
import MaTimePicker from "@/components/MaTimePicker.vue";
const RemoveAppointment = () => import("./RemoveAppointment.vue");
import timeHhMm from "@/utils/timeHhMm";
import { RolLabel } from "@/utils/rolesStatus";
import rules from "@/components/account/rules";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import { AxiosResponse } from "axios";
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: { RemoveAppointment, MaTimePicker },
  name: "event-viewer",
  props: {
    value: Boolean,
    event: Object,
    selectedElement: [HTMLDivElement, HTMLElement],
  },
  data() {
    return {
      editInitialReal: false,
      editEndReal: false,
      realInitialtime: "",
      loadingr: false,
      loadingi: false,
      realEndtime: "",
      validForm: false,
      window: 0,
      rules,
      showRemove: false,
      RoleLabel: RolLabel,
      loading: false,
      loadingPatientData: false,
      patient: null,
      types: [
        { label: "Cash", value: "CASH" },
        { label: "Card", value: "Card" },
        { label: "Zelle", value: "Zelle" },
        { label: "PayPal", value: "Paypal" },
        { label: "Square", value: "Square" },
        { label: "Alphaeon", value: "Alphaeon" },
        { label: "United Medical Credit", value: "UnitedMedicalCredit" },
        { label: "CareCredit", value: "CareCredit" },
      ],
      form: {
        type: "Card",
        details: "",
        amount: 0,
        identifier: "",
      },
    };
  },

  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "isWorker",
      "isDoctor",
      "isSupervisor",
      "getpatientN",
    ]),

    subtype(): string | false {
      let result = "Not defined";
      const data = this.event.data;

      if (data == undefined || data == null) return false;

      if (data.procedure.treatmentType !== null) {
        return data.procedure.treatmentType.name;
      } else if (data.procedure.surgeryType !== null) {
        return data.procedure.surgeryType.name;
      }
      return result;
    },
    total(): number {
      if (
        this.event === null ||
        this.event === undefined ||
        this.patient === null ||
        this.patient === undefined
      )
        return 0;

      const procedure = (this as any).patient.proceduresEntity.find(
        (procedure: any) => procedure.id == this.event.data.procedure.id
      );
      if (procedure === undefined || procedure === null) return 0;

      return procedure.totalTreatment;
    },
    deb(): number {
      if (
        this.event == null ||
        this.event == undefined ||
        this.patient == null ||
        this.patient == undefined
      )
        return 0;

      const procedure = (this as any).patient.proceduresEntity.find(
        (procedure: any) => procedure.id == this.event.data.procedure.id
      );
      if (procedure === undefined || procedure === null) return 0;
      let payments = 0.0;

      const paids = procedure.payments;
      paids.forEach((pay: any) => {
        const p = pay.status == "CONFIRMED" ? parseFloat(pay.amount) : 0.0;
        payments = payments + p;
      });
      /*  .reduce(
        (total: number, current: any) => total + parseFloat(current.amount),
        0
      ); */

      return (this.total - payments) | 0;
    },

    initialRealtime() {
      if (this.event.data == null || this.event.data.realInitialTime == null) {
        return "-";
      }
      const date = moment(this.event.data.realInitialTime).format("hh:mm a");
      return date;
    },
    endRealtime() {
      if (this.event.data == null || this.event.data.realEndTime == null) {
        return "-";
      }
      const date = moment(this.event.data.realEndTime).format("hh:mm a");
      return date;
    },
  },
  watch: {
    value(val: boolean) {
      if (val == false) this.window = 0;

      this.$emit("input", val);
    },
    event: {
      handler: function () {
        this.loadingPatientData = true;

        if (this.event.data) {
          getAPI("/patient/getPatient/" + this.event.data.patient.uuid).then(
            (response: AxiosResponse) => {
              this.loadingPatientData = false;
              this.patient = response.data;
            }
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("crmDashboardModule", ["mutprocedureSelectid"]),
    timeHhMm,
    moment: moment,
    toEdit(timetype: string) {
      if (timetype == "end") {
        this.editEndReal = true;
      } else {
        this.editInitialReal = true;
      }
    },
    cancelEdit(timetype: string) {
      if (timetype == "end") {
        this.editEndReal = false;
      } else {
        this.editInitialReal = false;
      }
    },
    confirmEdit(timetype: string) {
      const uuid = this.event.data.uuid;
      const initialday = this.event.data.initialTime.split("T")[0];
      if (timetype == "end") {
        this.loadingr = true;
        const ret = moment(initialday + " " + this.realEndtime)
          .utc()
          .toISOString();

        getAPI
          .put("/patient/uptadeDate/" + uuid, { realEndTime: ret })
          .then(() => {
            this.loadingr = false;
            this.$emit("removed", false);
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingr = false;
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        this.loadingi = true;
        const rit = moment(initialday + " " + this.realInitialtime)
          .utc()
          .toISOString();

        getAPI
          .put("/patient/uptadeDate/" + uuid, { realInitialTime: rit })
          .then(() => {
            this.loadingi = false;
            this.$emit("removed", false);
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingi = false;
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    closeDialog() {
      this.showRemove = false;
      this.$emit("input", this.value ? false : true);
    },
    onRemove() {
      this.$emit("input", false);
      this.$emit("removed", false);
    },
    reset() {
      (this.form.type = "Card"),
        (this.form.details = ""),
        (this.form.amount = 0),
        (this.form.identifier = "");
    },

    toPatient() {
      if (this.event.data.procedure) {
        this.mutprocedureSelectid(this.event.data.procedure.id);
      }

      this.$router.push("/patients/details/" + this.event.data.patient.uuid);
    },

    makePayment() {
      this.loading = true;
      const procedureId = this.event.data.procedure.id;

      getAPI
        .post("/patient/createPayment", {
          ...this.form,
          procedure: procedureId,
        })
        .then(() => {
          this.loading = false;
          notifyInfo("The procedure have been payment");
          this.reset();
          this.closeDialog();
          this.$emit("paidout");
          this.patient = null;
        })
        .catch((error) => {
          this.loading = false;
          notifyError(
            error.response.data.message || error.response.data.details
          );
          this.reset();
          this.window = 0;
        });
    },
  },
});
